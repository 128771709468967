import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Breadcrumbs from "../components/Breadcrumbs"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SubHero from "../components/SubHero"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { Helmet } from "react-helmet"

const Events = ({ location }) => {
  const data = useStaticQuery(graphql`
    query EventsQuery {
      wpPage(databaseId: { eq: 5617 }) {
        id
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphType
          canonical
        }
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        title
        content
      }
    }
  `)
  let seoData = data.wpPage.seo
  let featuredImage = data.wpPage.featuredImage

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Events",
  })

  const pageData = {
    subHeroTitle: data.wpPage.title,
    subHeroImage: {
      sourceUrl: featuredImage.node.sourceUrl,
    },
  }

  return (
    <Layout>
      <Helmet>
        <script type="text/javascript">
          {`var embeddable_event_widget_options = {
    script_url: "https://www.mketech.org/?embed=wp_event_manager_widget",
    keywords: "",
    location: "",
    categories: "",
    event_types: "",
    per_page: "18",
    pagination: "1",
  }`}
        </script>
        <script type="text/javascript" src="https://www.mketech.org/wp-content/plugins/wp-event-manager-embeddable-event-widget/assets/js/embed.min.js"></script>
      </Helmet>
      <Seo title={seoData.title} description={seoData.metaDesc} uri={seoData.canonical} socialImage={featuredImage} />
      <Breadcrumbs crumbs={crumbs} />

      <SubHero hero={pageData} />
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
        <div id="embeddable-event-widget">
          {/* <div id="embeddable-event-widget-heading">
            Events Form <a href="https://www.mketech.org/">MKE TECH</a>
          </div> */}
          <div id="embeddable-event-widget-content"></div>
        </div>
      </div>
    </Layout>
  )
}

export default Events
